<template>
    <b-img
      v-bind="mainProps"
      :src="img"
      alt="AnyPay"
    />
</template>
<script>
import { BImg
} from 'bootstrap-vue'
export default {
  components: {
    BImg
  },
   data() {
     return {
     img: require('@/assets/images/logo/AnyPayROUND_240px.png'),
     mainProps: {
      width: 150,
      height: 50,
    },
    }
   },
   computed: {
    imgUrl() {
      return this.img
    },
  },
};
</script>