<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
       <!--  <h2 class="brand-text mb-0">
          {{ appName }}
        </h2> -->
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to Anypay! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Login Id"
                label-for="login-id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="loginId"
                  vid="loginId"
                  rules="required"
                >
                  <b-form-input
                    id="login-id"
                    v-model="loginId"
                    :state="errors.length > 0 ? false:null"
                    name="login-id"
                    placeholder="Enter LoginId"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:6"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loading"
              >
                <b-spinner
                  v-show="loading"
                  small
                />
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cookies from 'js-cookie'
import Common from '../../services/common.service'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'


export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    Logo,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      loginId: '',
      loading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  created() {
    this.keyRemove()
    if(this.$route.query.message != '' && this.$route.query.message !== undefined){
       this.$toast({
            component: ToastificationContent,
            props: {
              title: '',
              text: this.$route.query.message,
              icon:'LockIcon',
              variant: 'success',
            },
          })
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
   
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },

  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  methods: {
     keyRemove(){
      Common.keyRemove();
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          const payload = {
            checkbox_remember_me: this.checkbox_remember_me,
            userDetails: {
              loginId: this.loginId,
              password: this.password,
            },
          }

          this.$store.dispatch('login', payload).then(response => {
            let ability = [{
              action: 'API_B2B_USER',
              subject: 'AccessBothUser',
            }]
            if (response && response.type === 'API') {
              ability = [{
                action: 'API_B2B_USER',
                subject: 'AccessBothUser',
              }]
            } else if (response && response.type === 'Software') {
              ability = [{
                action: 'API_B2B_USER',
                subject: 'AccessBothUser',
              },
              {
                action: 'B2B',
                subject: 'B2BUser',
              },
              ]
            }
            this.$ability.update(ability)
            this.loading = false
            if (!response.verified) {
              this.$router.push('/verify')
            } else if(response.is_new_user) {
              this.$router.push('/details')
            } else{
              this.$router.push('/dashboard')
            }
          },
          error => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something wrong',
                text:error.response.data.message,
                icon: 'LockIcon',
                variant: 'danger',
              },
            })
          })
        }
      }) 
    },
  },
}

</script>
<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';

</style>
